<template>
  <backoffice-container>
    <!-- Loading -->
    <div v-if="isLoading">
      <b-spinner type="grow" small class="text-primary mx-auto mt-3 d-block" />
    </div>
    <div v-else>
      <!-- Search -->
      <b-form-input
        v-model="searchInput"
        type="text"
        class="mr-2 d-block mx-auto mb-3"
        style="width:450px;"
        :placeholder="$t('form-create-item.search-input')"
        @input="searchItems"
      />
      <!-- Container with data -->
      <div v-if="items.length > 0" class="cards-container">
        <b-row>
          <!-- Items Loop -->
          <b-col
            v-for="item in items"
            :key="item.key"
            class="x-scroll-element mx-25 mb-3 p-0 d-flex justify-content-center"
          >
            <b-card :key="item.key" class="x-scroll-element mb-1 mx-50 p-0 card-blog float-left">
              <!-- :img-src="getImageSrc(item.imageUrl) || Placeholder" -->
              <b-card-header class="p-0">
                <safe-img
                  :src="getImageSrc(item.imageUrl)"
                  :placeholder="Placeholder"
                  alt="profile photo"
                  class="card-img"
                  img-top
                />
              </b-card-header>
              <div style="padding: 1.5rem;">
                <b-link :to="{ name: 'addon', params: { id: item.key } }">
                  <h4 v-if="item.name">
                    {{ item.name[currentLocale] || Object.values(item.name)[0] }}
                  </h4>
                  <b-card-text>
                    <p
                      v-if="item.tagline"
                      class="min-height-3 html-text-ellipsis-2 text-secondary"
                      v-html="item.tagline[currentLocale] || Object.values(item.tagline)[0]"
                    />
                    <p
                      v-else
                      class="min-height-3 html-text-ellipsis-2 text-secondary"
                      v-html="item.headline[currentLocale] || Object.values(item.headline)[0]"
                    />
                  </b-card-text>
                </b-link>
                <b-card-footer class="pl-0">
                  <b-row>
                    <b-col
                      v-b-tooltip.hover
                      :title="$t('tooltips.addons.install')"
                      cols="2">
                      <label for="rol-name" class="font-weight-bold"> {{ $t('integrations.installed') }}</label>
                    </b-col>
                    <b-col cols="2" offset="1">
                      <b-form-checkbox
                        v-model="item.isInstalled"
                        v-b-tooltip.hover
                        :title="$t('tooltips.addons.install')"
                        :disabled="loading"
                        switch
                        @input="editItem(item.key, 'install')"
                      />
                    </b-col>
                    <b-col
                      v-if="item.isInstalled"
                      v-b-tooltip.hover
                      :title="$t('tooltips.addons.publish')"
                      cols="2"
                      offset="2"
                    >
                      <label for="rol-name" class="font-weight-bold"> {{ $t('integrations.published') }}</label>
                    </b-col>
                    <b-col v-if="item.isInstalled" cols="2" offset="1">
                      <b-form-checkbox
                        v-model="item.isPublished"
                        v-b-tooltip.hover
                        :title="$t('tooltips.addons.publish')"
                        :disabled="loading"
                        switch
                        @input="editItem(item.key, 'publish')"
                      />
                    </b-col>
                  </b-row>
                </b-card-footer>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- Load More items -->
        <div v-if="items.length > 100" class="w-100 float-left">
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t('action.load-more') }}
          </b-button>
          <div v-else class="w-100">
            <b-spinner
              class="center-x my-3"
              small
              variant="primary"
              type="grow"
              label="Loading..."
            />
          </div>
        </div>
      </div>
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="generalPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('available.message', { itemName: 'items' }) }}
          </p>
        </b-col>
      </b-row>
    </div>
  </backoffice-container>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import GeneralPlaceholder from '@/assets/images/placeholders/light/general-placeholder.svg';

export default {
  name: 'AddonsList',
  components: {
    BackofficeContainer,
    SafeImg,
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      searchInput: null,
      results: null,
      loading: false,
    };
  },
  computed: {
    //1) addons from store
    itemsData() {
      return this.$store.getters.addons;
    },
    //2) addons unpaginated
    addonsUnpaginated() {
      return this.itemsData?.unpaginated;
    },
    //3) addons sorted by name
    itemsSorted() {
      if (!this.itemsData || this.itemsData.length === 0 || !this.addonsUnpaginated) {
        return [];
      }
      // order by name in selected lang
      // this.addonsUnpaginated.sort((a, b) => (a.name[this.currentLocale] > b.name[this.currentLocale] ? 1 : -1));
      this.addonsUnpaginated.sort((a, b) => ((a.isInstalled * 2 + a.isPublished * 3 + a.name[this.currentLocale]) > (b.isInstalled * 2 + b.isPublished * 3 + b.name[this.currentLocale]) ? 1 : -1));

      return this.addonsUnpaginated;
    },
    //4) addons or sorted or filtered --> the one to iterate with
    items() {
      const itemsToIterate = this.searchInput ? this.results : this.itemsSorted;
      return itemsToIterate;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    Placeholder() {
      return Placeholder;
    },
    generalPlaceholder() {
      return GeneralPlaceholder;
    },
  },
  async created() {
    this.isLoading = true;
    if(!this.addonsUnpaginated?.length){
      await this.fetchData();
    }
    console.log(this.addonsUnpaginated);

    this.isLoading = false;
  },
  methods: {
    async editItem(key, type) {
      this.loading = true;
      await this.$store.dispatch('editItem', {
        item: {
          itemType: `${type}Addon`,
          customName: 'addons',
          requestConfig: {
            addonKey: key,
          },
        },
      });
      this.loading = false;
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData() {
      //get all addons and integrations
      await this.$store.dispatch('getItems', {
        itemType: 'addons',
        page: this.lastLoadedPage,
        forceAPICall: true,
        requestConfig: {
          count: 100,
          //type: 'addons',
        },
      });

      this.isLoading = false;
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter(
        (item) => item.name[this.currentLocale].toLowerCase().includes(searchTerm)
          || Object.values(item.name)[0]
            .toLowerCase()
            .includes(searchTerm) || item.key.includes(searchTerm),
      );
    },
  },
};
</script>
<style scoped>
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}
.card-img {
  height: 100px !important;
  object-fit: fill !important;
}
.card-body {
  padding: 0;
}
.card-blog {
  cursor: default !important;
  height: 270px;
  width: 300px !important;
}
</style>
